<template>
  <div>
      <el-divider></el-divider>
      <div style="display:-webkit-box;"> 
        <el-image :src="item.product.image" fit="fit" style="width:80px;height:80px"></el-image>
        <div style="-webkit-box-flex:1;text-align:left;margin-left:20px;">
            <div>{{item.product.name}}</div>
            <div style="margin-top:10px;color:grey;">{{item.sku.name}}</div>
        </div>
        <div style="width:200px;text-align:left;">
            <div class="cart-item-price-3" v-if="isMember">${{(item.sku.member_price/100).toFixed(2)}}</div>
            <div class="cart-item-price-3" v-else>${{(item.sku.price/100).toFixed(2)}}</div>
        </div>
        <div style="width:160px;text-align:left;line-height:84px;">
            <el-input-number size="small" :min="0" :max="999" :step="1" v-model="item.amount" style="width:120px;" @change="amountChangeClick"></el-input-number>
        </div>
        <div style="margin-top:0px;display:-webkit-box;width:100px;">
            <div class="cart-item-price-2" v-if="isMember">${{(item.sub_member_total/100).toFixed(2)}}</div>
            <div class="cart-item-price-2" v-else>${{(item.sub_total/100).toFixed(2)}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import {mapState, mapGetters ,mapActions} from 'vuex'

export default {
    name: 'bigCartItem',
    props: ['item'],
    computed: {
        ...mapState('client', ['client']),
        ...mapGetters('client', ['isMember'])
    },
    methods: {
        ...mapActions('cart', [
            'changeGoodAmount',
            'removeGood'
        ]),
        amountChangeClick(val, oldval){
            console.log(val, oldval)
            if(val === 0){
                this.removeGood({
                    key: this.item.key
                 })
            } else {
                this.changeGoodAmount({
                    key: this.item.key,
                    amount: val
                })
            }
        }
    }
}
</script>

<style>
.cart-item-price-2 {
    line-height: 84px;
    -webkit-box-flex:1;
    text-align: right;
}
.cart-item-price-3 {
    line-height: 84px;
}
</style>